<template>
    <div>
        <a-row :gutter="24" type="flex">
            <a-col :span="24" class="mb-24">
                <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: 0 }">
                    <template #title>
                        <a-row type="flex" align="middle">
                            <a-col :span="24" :md="12">
                                <h5 class="font-semibold m-0">
                                    {{ $t('cms.status_course_all_student', { title: courseTitle }) }}
                                </h5>
                            </a-col>
                        </a-row>
                    </template>
                    <template>
                        <v-data-table
                            :headers="tableColumns"
                            :items="courseRecordList"
                            :page="page"
                            :no-data-text="$t('cms.no_data_available')"
                            :loading="isLoadingCourseRecord"
                            :loading-text="$t('cms.loading')"
                            class="elevation-1"
                            hide-default-footer
                        >
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <div class="download-list" @click="downloadList">
                                        <span class="mdi mdi-file-document"></span>
                                        <span class="ml-1">{{ $t('cms.download_all') }}</span>
                                    </div>

                                    <v-spacer></v-spacer>

                                    <div class="search">
                                        <a-select :default-value="selectedField" @change="handleChangeSelect">
                                            <a-select-option
                                                v-for="(item, index) in fieldsSearch"
                                                :key="index"
                                                :value="item.value"
                                            >
                                                {{ item.title }}
                                            </a-select-option>
                                        </a-select>
                                        <a-input-search
                                            v-model="search"
                                            :placeholder="$t('header.search')"
                                            @change="onSearchItem"
                                        />
                                    </div>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.email`]="{ item }">
                                <div class="my-2 line-clamp-1">
                                    <span>{{ (item.user && item.user.email) || '-' }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.full_name`]="{ item }">
                                <div class="my-2 line-clamp-1">
                                    <span>{{ (item.user && item.user.full_name) || '-' }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.progress`]="{ item }">
                                <v-progress-linear :value="item.progress" :style="{ width: '75%' }" height="25">
                                    <strong> {{ renderProgress(item.progress) }}% </strong>
                                </v-progress-linear>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <router-link
                                    :to="{
                                        path: '/chapters-of-course',
                                        query: { user_id: item.user_id, course_id: item.course_id },
                                    }"
                                >
                                    <v-btn color="indigo" dark small>
                                        <v-icon left> mdi mdi-format-list-bulleted-type </v-icon>
                                        &nbsp;{{ $t('cms.detail') }}
                                    </v-btn>
                                </router-link>
                            </template>
                        </v-data-table>
                        <div class="text-center py-5">
                            <f-pagination
                                v-model="page"
                                :pageSize="courseRecordPagination.pageSize"
                                :totalItems="courseRecordPagination.totalItems"
                                :disabled="isLoadingCourseRecord"
                            />
                        </div>
                    </template>
                </a-card>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import * as _ from 'lodash';
import { mapState, mapActions } from 'vuex';

import { Course } from '../../api';
import { mixinFormats, mixinToasts } from '../../mixins';

export default {
    mixins: [mixinFormats, mixinToasts],
    data() {
        return {
            // TABLE VARIABLES
            tableColumns: [
                { text: this.$t('form.email'), value: 'email', sortable: false, width: '200px' },
                { text: this.$t('form.full_name'), value: 'full_name', sortable: false, width: '200px' },
                { text: this.$t('cms.finish_rate'), value: 'progress', sortable: false, width: '200px' },
                { text: this.$t('cms.action'), value: 'actions', sortable: false, width: '100px' },
            ],
            page: 1,

            // SEARCH VARIABLES
            fieldsSearch: [
                {
                    title: this.$t('form.full_name'),
                    value: 'full_name',
                },
                {
                    title: this.$t('form.email'),
                    value: 'email',
                },
                {
                    title: this.$t('cms.finish_rate'),
                    value: 'progress',
                },
            ],
            selectedField: 'full_name',
            search: '',

            // OTHER VARIABLES
            courseID: this.$route.params.id,
            courseTitle: '',
        };
    },
    computed: {
        ...mapState('recordCourse', ['isLoadingCourseRecord', 'courseRecordList', 'courseRecordPagination']),
    },
    async mounted() {
        await this.getList({ page: this.page, field: this.selectedField, search: this.search });
        await this.getCourse();
    },
    watch: {
        page(val) {
            this.getList({ page: val, field: this.selectedField, search: this.search });
        },
    },
    methods: {
        ...mapActions('recordCourse', ['getCourseRecordList']),

        // -------------------- FUNCTION GET DATA ---------------------
        async getCourse() {
            try {
                const res = await Course.getOne(this.courseID, { fields: ['id', 'title'] });
                this.courseTitle = res?.results?.object?.title;
            } catch (error) {
                this.showError(error);
            }
        },

        async getList(data) {
            this.page = data?.page || 1;
            this.selectedField = data?.field || 'full_name';
            this.search = data?.search || '';
            const res = await this.getCourseRecordList({ ...data, course_id: this.courseID });

            if (!res?.isSuccess) {
                this.showError(res?.mess);
            }
        },
        // ---------------------------------------------------------------------

        // -------------------- FUNCTION DOWNLOAD ---------------------
        async downloadList() {
            try {
                const res = await Course.dowloadCourseRecord({ course_id: this.courseID });
                const { url } = res.results.object;

                if (url) {
                    let fileLink = document.createElement('a');
                    fileLink.href = url;
                    fileLink.setAttribute('download', 'file.xlsx');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                }
            } catch (error) {
                this.showError(error);
            }
        },
        // ---------------------------------------------------------------------

        // -------------------- FUNCTION SEARCH ---------------------
        onSearchItem: _.debounce(function (e) {
            this.getList({ page: 1, field: this.selectedField, search: this.search });
        }, 500),

        handleChangeSelect(value) {
            this.getList({ page: 1, field: value, search: this.search });
        },
        // ----------------------------------------------------------

        // -------------------- OTHER FUNCTION ----------------------
        renderProgress(value) {
            return Math.round(value);
        },
        // ----------------------------------------------------------
    },
};
</script>

<style lang="scss">
.table-image {
    width: 45px;
    height: 45px;
}

.search {
    & .ant-select {
        margin-right: 10px;
        width: 120px;
    }

    & .ant-input-search {
        width: 500px;
    }
}

.download-list {
    color: cornflowerblue;
    cursor: pointer;
}
</style>
