var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-row',{attrs:{"gutter":24,"type":"flex"}},[_c('a-col',{staticClass:"mb-24",attrs:{"span":24}},[_c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ padding: 0 }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('a-row',{attrs:{"type":"flex","align":"middle"}},[_c('a-col',{attrs:{"span":24,"md":12}},[_c('h5',{staticClass:"font-semibold m-0"},[_vm._v(" "+_vm._s(_vm.$t('cms.status_course_all_student', { title: _vm.courseTitle }))+" ")])])],1)]},proxy:true}])},[[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableColumns,"items":_vm.courseRecordList,"page":_vm.page,"no-data-text":_vm.$t('cms.no_data_available'),"loading":_vm.isLoadingCourseRecord,"loading-text":_vm.$t('cms.loading'),"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('div',{staticClass:"download-list",on:{"click":_vm.downloadList}},[_c('span',{staticClass:"mdi mdi-file-document"}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('cms.download_all')))])]),_c('v-spacer'),_c('div',{staticClass:"search"},[_c('a-select',{attrs:{"default-value":_vm.selectedField},on:{"change":_vm.handleChangeSelect}},_vm._l((_vm.fieldsSearch),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.title)+" ")])}),1),_c('a-input-search',{attrs:{"placeholder":_vm.$t('header.search')},on:{"change":_vm.onSearchItem},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2 line-clamp-1"},[_c('span',[_vm._v(_vm._s((item.user && item.user.email) || '-'))])])]}},{key:"item.full_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2 line-clamp-1"},[_c('span',[_vm._v(_vm._s((item.user && item.user.full_name) || '-'))])])]}},{key:"item.progress",fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{style:({ width: '75%' }),attrs:{"value":item.progress,"height":"25"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.renderProgress(item.progress))+"% ")])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                                    path: '/chapters-of-course',
                                    query: { user_id: item.user_id, course_id: item.course_id },
                                }}},[_c('v-btn',{attrs:{"color":"indigo","dark":"","small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi mdi-format-list-bulleted-type ")]),_vm._v("  "+_vm._s(_vm.$t('cms.detail'))+" ")],1)],1)]}}],null,true)}),_c('div',{staticClass:"text-center py-5"},[_c('f-pagination',{attrs:{"pageSize":_vm.courseRecordPagination.pageSize,"totalItems":_vm.courseRecordPagination.totalItems,"disabled":_vm.isLoadingCourseRecord},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }